import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/digital_technology_services.jpg'
import person from '../../images/person.png'
import digital_technology_services from '../../images/pages/digital_technology_services.jpg'

const pageTitle = "Digital Technology Services";
const pageSlug = "digital-technology-services";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="digital-consulting-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Our digital technology services span across the entire 
            technology industry and include project and program management, 
            cloud & mobile solutions, automation, and workflow.</h6>
        </div>
      </section>

      <section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Digital initiatives need wide-ranging capabilities. 
                    Customer-centered thinking and well designed, user-friendly interfaces.
                    </h3>
                 <p>However, digital transformation requires developing comprehensive end-to-end 
                     processes, organizational change management, and technology capabilities.</p>
                <span className="break"></span>
                <h4>Digital Technology Expertise</h4>
                <p>At Nuerex, we understand technology, learn more about our capabilities:</p>
                <h5>Mobile App Development</h5>
                <p>Our mobile app development team can help you create business transformation 
                    as well as opportunities for growth, innovation, and customer experience.</p>
                <h5>Program and Project Management</h5>
                <p>Enterprise Project, Programs, and Portfolio Management will allow your 
                    projects to align with your strategic purpose. Ensuring that solutions are 
                    delivered on time and within budget.</p>
                <h5>Cloud Computing</h5>
                <p>Our team can help you determine how cloud services should fit within your 
                    existing IT assets. Together we can implement a cloud solution that 
                    improves and transforms your processes.</p>
                <h5>Custom Development</h5>
                <p>We strive to create solutions and design systems that are scalable, easy to 
                    use, and fast to maintain. At Nuerex, Software development is our craft, 
                    and we’re always willing to help.</p>
                <h5>Agile</h5>
                <p>Nuerex’s agile approach allows for a predictable metrics oriented delivery 
                    of functionality that is better, faster, and cheaper.</p>
                <h5>Analytics</h5>
                <p>We offer Data and Analytics services that go beyond the boundaries of traditional 
                    Business Intelligence. To help our clients transform their data into information 
                    that can provide value-creating insight.</p>
                <h5>Automation and Workflow</h5>
                <p>Our team can create roadmaps and identifying requirements, solutions and integrations 
                    that ensure Marketing Operations work seamlessly and to scale.</p>
                <h5>Nuerex’s Digital Approach</h5>
                <p>We believe that digital transformation requires comprehensive capabilities. Having a 
                    business strategy for the digital world requires far more than campaigns 
                    and redesigning your website.</p>
                <span className="break"></span>
                <img src={digital_technology_services} />
                <span className="break"></span>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
